import { Link } from "@remix-run/react";

import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import { routes } from "~/lib/routes";

export function LoggedOutDialog({ open }: { open: boolean }) {
  return (
    <Dialog open={open}>
      <DialogContent hideClose>
        <DialogHeader>
          <DialogTitle>Session Expired</DialogTitle>
          <DialogDescription>Your session has expired. Please login again.</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button asChild>
            <Link to={routes.login()} prefetch="intent">
              Login
            </Link>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
