import { useQuery } from "@tanstack/react-query";

import { client } from "~/services/client";

export function useUser() {
  return useQuery({
    queryKey: ["user"],
    queryFn: () => client.getCurrentUser(),
  });
}
