import { useParams } from "@remix-run/react";
import { createContext, useContext, useEffect, useState } from "react";

import { LoggedOutDialog } from "~/components/logged-out-dialog";
import { usePermissions } from "~/hooks/usePermissions";
import { useUser } from "~/hooks/useUser";
import { Organization, ProductPermission } from "~/lib/schema";
import { getInitials } from "~/lib/utils";

type AuthCtx = {
  avatar?: string | null;
  initials?: string;
  lastName?: string | null;
  firstName?: string | null;
  emailAddress?: string;
  isLoaded: boolean;
  currentOrg: Organization | null;
  organizations?: Array<Organization & { products: Array<ProductPermission> }>;
};

const initialCtx: AuthCtx = {
  avatar: "",
  initials: "",
  lastName: "",
  firstName: "",
  emailAddress: "",
  isLoaded: false,
  currentOrg: null,
  organizations: [],
};

const DeviceContext = createContext<AuthCtx>(initialCtx);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const user = useUser();
  const params = useParams();
  const permissions = usePermissions();
  const [forceLoginModalOpen, setForceLoginModalOpen] = useState(false);

  const organizationId = params.organizationId;
  const currentOrg = permissions.data?.organizations.find((org) => org.id === organizationId) ?? null;

  // If the user is logged out, show the login modal
  const isLoggedOut = user.isError;
  useEffect(() => setForceLoginModalOpen(() => isLoggedOut), [isLoggedOut]);

  const isLoaded = user.isSuccess && permissions.isSuccess;

  const value = {
    currentOrg,
    initials: getInitials(`${user.data?.firstName ?? ""} ${user.data?.lastName ?? ""}`),
    organizations: permissions.data?.organizations,
    isLoaded,
    ...user.data,
  };

  return (
    <DeviceContext.Provider value={value}>
      {user.isSuccess ? children : <div className="absolute inset-0 z-[49] bg-white"></div>}
      <LoggedOutDialog open={forceLoginModalOpen} />
      {/* DEBUG */}
      <div className="group fixed bottom-20 left-4 isolate z-50 rounded-2xl bg-white px-2 py-1 opacity-50 shadow-lg hover:size-auto hover:p-5 hover:opacity-100">
        <span className="text-xs font-medium group-hover:hidden">User</span>
        <pre className="hidden whitespace-pre-wrap text-xs group-hover:block">{JSON.stringify(value, null, 2)}</pre>
      </div>
    </DeviceContext.Provider>
  );
}

function useAuth() {
  const context = useContext(DeviceContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
