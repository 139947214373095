import { useQuery } from "@tanstack/react-query";

import { useUser } from "~/hooks/useUser";
import { client } from "~/services/client";

export function usePermissions() {
  const user = useUser();

  return useQuery({
    enabled: user.isSuccess,
    queryKey: ["permissions"],
    queryFn: () => client.getPermissions(),
  });
}
